.header {
  position: relative;
  background-color: var(--header-background-color);
  height: 60px;
  display: flex;
  align-items: center;
  padding: 8px;
  color: var(--header-color);
}

.logo {
  fill: white;
  height: 26px;
  margin-right: 20px;
}

.spacer {
  flex: 1;
}

.buttons {
  text-align: right;
}

.buttons button {
  margin-left: 8px;
}

.codeButtons {
  position: relative;
  flex: 1 0 auto;
  padding-right: 32px;
}

.connection-status {
  margin-left: 10px;
}

.codeButtons::after {
  content: '';
  position: absolute;
  top: 0;
  right: 11px;
  width: 2px;
  background-color: white;
  height: 100%;
}
