body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding: 2px;
  background-color: lightgray;
  /* border: 1px solid darkgray; */
  color: black;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre;
}

p.error {
  color: red;
  white-space: pre;
}

p.line {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  white-space: pre;
}
