.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 12px;
  color: var(--list-color);
  background-color: var(--list-background-color);
  border: 1px solid var(--list-border-color);
  border-radius: var(--list-border-radius);
  height: 240px;
}

.wrapper h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.wrapper:hover h2 {
  text-decoration: underline;
}

.description {
  overflow: auto;
  line-height: 1.5;
}

.open {
  margin-top: 12px;
  text-decoration: underline;
}
