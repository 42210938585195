body {
  overflow: hidden;
}

.run {
  background-color: var(--challenge-background-color--light);
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.leftSide {
  width: 55%;
}

.rightSide {
  width: 45%;
}

.label {
  margin-left: 16px !important;
  margin-bottom: -30px;
  margin-top: -12px;
}

.title {
  height: 50px;
}

.description {
  background-color: var(--challenge-background-color--light);
  color: var(--body-color);
  padding: 0 12px;
  padding-top: 12px;
  overflow: scroll;
}

.description p {
  line-height: 1.5;
  white-space: pre-wrap;
}

.output {
  background-color: var(--challenge-background-color--dark);
  color: var(--challenge-color--dark);
  padding: 0 12px;
  overflow: scroll;
}

.editor {
  padding-top: 8px;
  height: 100%;
}

.red {
  color: var(--output-red-color);
}

.lightRed {
  color: var(--output-light-red-color);
}

.gray {
  color: var(--output-gray-color);
}

.inverse {
  background-color: var(--output-inverse-background-color);
  color: var(--output-inverse-color);
}

.bold {
  font-weight: bold;
}

.bgRed {
  background-color: var(--output-red-background-color);
}

.yellow {
  color: var(--output-yellow-color);
}

.lightYellow {
  color: var(--output-light-yellow-color);
}

.green {
  color: var(--output-green-color);
}

.blue {
  color: var(--output-blue-color);
}

.cyan {
  color: var(--output-cyan-color);
}

.lightCyan {
  color: var(--output-light-cyan-color);
}
