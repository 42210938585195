.root div {
  background-color: var(--input-background-color) !important;
}

.root label {
  color: var(--input-label-color) !important;
}

.root div::after {
  border-color: var(--primary);
}
