.item {
  list-style-type: none;
  padding: 6px;
}

.list {
  width: 100%;
  max-width: 500px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
