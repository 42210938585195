.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.text {
  max-width: 500px;
  text-align: center;
  font-size: 1.2rem;
}

.button {
  margin-top: 12px;
  text-align: center;
}
