.wrapper {
  color: var(--list-color);
  padding: 24px;
  width: 176px;
  background-color: var(--list-background-color);
  border: 1px solid var(--list-border-color);
  border-radius: var(--list-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper img {
  height: 128px;
}
