.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 12px;
  color: var(--list-color);
  background-color: var(--list-background-color);
  border: 1px solid var(--list-border-color);
  border-radius: var(--list-border-radius);
  margin-bottom: 8px;
}

.wrapper h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.wrapper:hover h2 {
  text-decoration: underline;
}

.info {
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.open {
  margin-top: 12px;
  text-decoration: underline;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submittedContainer {
  display: flex;
  align-items: center;
}

.submittedHeader {
  margin-right: 8px;
}

.remark {
  font-style: italic;
}

.centered {
  display: flex;
  align-items: center;
}
