* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background-color);
  color: var(--body-color);
}

#root,
.App {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100% - 60px);
}

.recordingWrapper {
  max-height: calc(100% - 60px - 48px);
}

h1,
h2,
h3,
h4 {
  color: var(--heading-color);
}
