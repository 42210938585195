.wrapper {
  display: flex;
  align-items: center;
  padding: 6px;
}

.text {
  font-size: 12px;
  padding: 0 6px;
}
