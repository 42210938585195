.wrapper {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 12px;
  align-items: center;
}

svg {
  fill: white;
}

.slider span {
  color: #fff;
  font-size: 10px;
}
